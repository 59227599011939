import React, { useContext } from "react";
import { NavigationContext } from "../Context/NavigationProvider";
import "./styles.scss";

const ButtonBurger = () => {
  const {isMenuOpen} = useContext(NavigationContext);
  const {toggleMenu} = useContext(NavigationContext);

    return (
      <button
        className={`btn btn__no-text btn__menu btn__menu-hamburger ${isMenuOpen ? "uk-open" : ""}`}
      onClick={() => toggleMenu()}
          >
        <span className="btn__icon"></span>
        <span className="btn__label">Menu</span>
      </button>
    );
  };

export default ButtonBurger;
