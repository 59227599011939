import { createContext } from "react";

const defaultUser = {
  details: {},
  auth: {},
  setUserContext: (user:any) => {}
};

const UserContext = createContext(defaultUser);

export default UserContext;