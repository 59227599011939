import Link from 'gatsby-plugin-transition-link';
import React from "react"
import Menu, { MenuItems } from "../Menu/Menu"

import "./styles.scss";

interface FooterProps {
  siteTitle: string
}


const menuArray: MenuItems[] = [
  {
    label: 'Nous contacter',
    icon: 'fas fa-envelope',
    color: "white",
    iconPosition: 'left',
    typeLink: 'mailto',
    status: 'public',
    link: 'mailto:contact@vudesquartiers.fr',
  },
  {
    label: 'Mentions légales',
    typeLink: 'route',
    link: '/mentions-legales',
    status: 'public',
    color: "white",
  },
  {
    label: 'Design by WEDODATA',
    svg: 'logoWdd',
    typeLink: 'link',
    target: 'blank',
    link: 'https://wedodata.Fr',
    status: 'public',
    color: "white",
  },
]


const Footer = ({ siteTitle }: FooterProps) => (
  <footer id="mainFooter" className="footer footer-main">
    <div className="footer_wrapper">
      <div className="footer_inner">
        {/* <div className="footer__brand"></div> */}
        <div className="footer__menu">
          <Menu name="FooterMenuMain" parent={'footer'} menuItems={menuArray} side="right" />
        </div>
      </div>
    </div>
  </footer>
)

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer