import React, { createContext, useState } from "react";

export const NavigationContext = createContext({
  isMenuOpen: false,
  toggleMenu: () => {},
  stateChangeHandler: (newState: any) => {}
});

const NavigationProvider = (props: any) => {
  const [menuOpenState, setMenuOpenState] = useState(false)
  
  return (
    <NavigationContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      stateChangeHandler: (newState: any) => setMenuOpenState(newState.isOpen)
    }}>
      {props.children}
    </NavigationContext.Provider>
  )
}


export default NavigationProvider;