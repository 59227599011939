import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import OwnMenu, { MenuItems } from "../Menu/Menu";
import ButtonBurger from "../ButtonBurger/ButtonBurger";

import "./styles.scss";
import { isLoggedIn } from "../../../services/AuthService";

interface HeaderProps {
  siteTitle: string,
  current?: string,
  subcurrent?: string
}

const menuArray: MenuItems[] = [
  {
    label: 'Devenir interlocuteur pour mon quartier',
    typeLink: 'route',
    link: '/membre',
    status: 'public',
    color: "yellow",
    onHomepage: false
  },
  {
    label: 'À propos',
    icon: 'fas fa-caret-down',
    color: "blue",
    typeLink: 'dropdown',
    status: 'public',
    link: '#',
    children: [{
      label: 'Le projet',
      typeLink: 'route',
      link: '/projet',
    },
    {
      label: 'L\'équipe',
      typeLink: 'route',
      link: '/equipe'
    },
    {
      label: 'Méthodologie',
      typeLink: 'route',
      link: '/methodologie',
    },
    {
      label: 'Charte d\'utilisation',
      typeLink: 'route',
      link: '/charte-d-utilisation'
    }
    ]
  },
  {
    label: 'Avec nous',
    typeLink: 'route',
    link: '/avec-nous',
    status: 'public',
    color: "blue",
  },
  {
    label: 'L\'annuaire des quartiers',
    icon: 'fas fa-lock',
    color: "blue",
    typeLink: 'route',
    link: '/annuaire',
    status: 'public'
  }
]

const menuArrayPrivate: MenuItems[] = [
  {
    label: 'Portrait des QPV',
    icon: "",
    color: "blue",
    typeLink: 'route',
    status: 'public',
    link: '/portrait-national',
  },
  {
    label: 'À propos',
    icon: 'fas fa-caret-down',
    color: "blue",
    typeLink: 'dropdown',
    status: 'public',
    link: '#',
    children: [{
      label: 'Le projet',
      typeLink: 'route',
      link: '/projet',
    },
    {
      label: 'L\'équipe',
      typeLink: 'route',
      link: '/equipe'
    },
    {
      label: 'Méthodologie',
      typeLink: 'route',
      link: '/methodologie',
    },
    {
      label: 'Charte d\'utilisation',
      typeLink: 'route',
      link: '/charte-d-utilisation'
    }
    ]
  },
  {
    label: 'Avec nous',
    typeLink: 'route',
    link: '/avec-nous',
    status: 'public',
    color: "blue",
  },
  {
    label: 'L\'annuaire',
    icon: '',
    color: "blue",
    typeLink: 'route',
    link: '/annuaire',
    status: 'public'
  },
  {
    label: 'Mon compte',
    icon: 'fas fa-user',
    color: "blue",
    typeLink: 'dropdown',
    status: 'private',
    link: '#',
    children: [{
      label: 'Réglage du compte',
      typeLink: 'route',
      link: '/utilisateur/mon-compte',
    },
    {
      label: 'Déconnexion',
      icon: "fas fa-sign-out-alt",
      iconPosition: 'left',
      typeLink: 'customFn',
      customFn: () => {
        if (!!localStorage && !!localStorage.getItem('userContext')) {
          localStorage.removeItem('userContext');
        }
        document.location.reload();
      }
    }
    ]
  },
]


const Header = ({ siteTitle, current, subcurrent }: HeaderProps) => (
  <header
    id="mainHeader"
    className={`header header-main ${"home" === current && 'header-home'}`}
  >
    <div className="header_wrapper">
      <div className="header_inner">
        <div className="header__brand">
          <AniLink
            fade
            entry={{ delay: 0.5 }}
            to="/"
          >
            <svg className="svg svg-logo logo-brand">
              <use xlinkHref="#logoVDQ"></use>
            </svg>
            <h2>{siteTitle}</h2>
          </AniLink>
        </div>
        <div className="header__menu">
          <OwnMenu name="HeaderMenuMain" current={current} subcurrent={subcurrent} menuItems={!!isLoggedIn() ? menuArrayPrivate : menuArray} side="right" />
        </div>
        <ButtonBurger />
      </div>
    </div>
  </header>
)



export default Header;