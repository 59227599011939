import React from "react";
import "./styles.scss";


type Props = {
    type: string
}

const Loader = ({
    type
}: Props) => {
    return (
        <div className={`loader loader-${type}`}>
            <div className="loader_inner">
                <div className="loader__elt">Chargement en cours...</div>
            </div>
        </div>
    );
};

export default Loader;