const getAuthLS = ():any => {
    const uc = ('undefined' !== typeof localStorage) ? localStorage.getItem('userContext') : null;
    return (!!uc) ? JSON.parse(uc) : null;
}

const getAuthToken = ():string|null => {
    const ls = getAuthLS();
    return (!!ls && !!ls.auth && !!ls.auth.token) ? ls.auth.token : null;
}

const isLoggedIn = ():boolean => {
    const ls = getAuthLS();
    return (!!ls && !!ls.auth && !!ls.auth.token);
}

const isAuthorized = ():boolean => {
    const ls = getAuthLS();
    return process.env.GATSBY_R_CHECK === ls.auth.r;
}

const cleanAuth = () => {
    if(!!getAuthLS()){
        localStorage.removeItem('userContext');
    }
}

export default getAuthToken;
export { isLoggedIn, isAuthorized, cleanAuth };