/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes, { string } from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  meta: any,
  title: string,
  titleOg?: string,
  titleOgTiwtter?: string,
  description?: string,
  descriptionOg?: string,
  descriptionOgTwitter?: string,
  imageOg?: string,
  imageOgTwitter?: string
}

function SEO({
  meta,
  title,
  titleOg,
  titleOgTiwtter,
  description,
  descriptionOg,
  descriptionOgTwitter,
  imageOg,
  imageOgTwitter
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )




  const metaDescription = description || site.siteMetadata.description;
  const opengraphTitle = !!titleOg ? titleOg : title;
  const opengraphTitleTwitter = !!titleOgTiwtter ? titleOgTiwtter : opengraphTitle;
  const opengraphDescription = !!descriptionOg ? descriptionOg : `"Vu des Quartiers" est un annuaire de référents dans les quartiers prioritaires, habitants et contacts, pour renouer un lien avec la presse et faire remonter l’information directement des quartiers. "Vu des Quartiers" est une initiative cofondée par l’association des maires Ville & Banlieue et BFMTV, et gérée par des rédactions de presse.`;
  const opengraphDescriptionTwitter = !!descriptionOgTwitter ? descriptionOgTwitter : metaDescription;
  const opengraphImage = !!imageOg ? imageOg : process.env.GATSBY_URL_WEBSITE + `/assets/images/share/generic.png`;
  const opengraphImageTwitter = !!imageOgTwitter ? imageOgTwitter : process.env.GATSBY_URL_WEBSITE + `/assets/images/share/generic-twitter.png`;

  return (
    <Helmet
      encodeSpecialCharacters={true}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defer={true}
      meta={
        [
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: opengraphTitleTwitter,
          },
          {
            name: `twitter:image`,
            content: opengraphImageTwitter
          },
          {
            name: `twitter:description`,
            content: opengraphDescriptionTwitter,
          },
        ].concat(meta)
      }
      script={
        [
          {
            src:
              "/assets/js/libs/uikit/js/uikit.min.js",
          }
        ]}
        onChangeClientState={(newState, addedTags, removedTags) => console.log(newState, addedTags, removedTags)}
    >
      <title>{title}</title>
      <meta name="description" content={metaDescription} />


      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={'Vu des Quartiers'} />

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
