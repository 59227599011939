/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import NavTouch from "../NavTouch/NavTouch";
import Header from "../Header/Header";
import Loader from "../../element/Loader/Loader";
import Footer from "../Footer/Footer";
import SvgSprite from "../SpriteSvg/SpriteSvg";
import UserProvider from "../Context/UserProvider";


import '../../../../assets/scss/basic.scss';
import "./styles.scss";
import NavigationProvider from "../Context/NavigationProvider";


interface LayoutProps {
  children: any,
  current?: string,
  subcurrent?: string
}

const Layout = ({ children, current, subcurrent }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // console.log("current");
  // console.log(current);

  return (
    <UserProvider>
      <Loader type="main" />
      <NavigationProvider>
        <Header siteTitle={data.site.siteMetadata.title} current={current} subcurrent={subcurrent} />
        <NavTouch current={current} subcurrent={subcurrent} />
        {children}
      </NavigationProvider>
      <Footer />
      <SvgSprite />
    </UserProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
