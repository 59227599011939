import React, {useState, useEffect, useContext} from "react";
import Link from 'gatsby-plugin-transition-link';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import OwnMenu, { MenuItems } from "../Menu/Menu";
import { elastic as Menu } from 'react-burger-menu';

import { isLoggedIn } from "../../../services/AuthService";


import ButtonBurger from "../ButtonBurger/ButtonBurger";


import "./styles.scss";
import { NavigationContext } from "../Context/NavigationProvider";



const menuArray: MenuItems[] = [
  {
    label: 'Devenir interlocuteur<br> pour mon quartier',
    typeLink: 'route',
    link: '/membre',
    status: 'public',
    color: "yellow",
  },
  {
    label: 'À propos',
    icon: 'fas fa-caret-down',
    color: "blue",
    typeLink: 'dropdown',
    status: 'public',
    link: '#',
    children: [{
      label: 'Le projet',
      typeLink: 'route',
      link: '/projet',
    },
    {
      label: 'L\'équipe',
      typeLink: 'route',
      link: '/equipe'
    },
    {
      label: 'Méthodologie',
      typeLink: 'route',
      link: '/methodologie',
    },
    // {
    //   label: 'Fonctionnement',
    //   typeLink: 'route',
    //   link: '/fonctionnement'
    // },
    {
      label: 'Charte d\'utilisation',
      typeLink: 'route',
      link: '/charte-d-utilisation'
    }
    ]
  },
  // {
  //   label: 'Méthodologie',
  //   typeLink: 'route',
  //   link: '/methodologie',
  //   status: 'public',
  //   color: "blue",
  // },
  {
    label: 'Avec nous',
    typeLink: 'route',
    link: '/avec-nous',
    status: 'public',
    color: "blue",
  },
  {
    label: 'L\'annuaire des quartiers',
    icon: 'fas fa-lock',
    color: "blue",
    typeLink: 'route',
    link: '/annuaire',
    status: 'public'
  },
  {
    label: 'Mon compte',
    icon: 'fas fa-caret-down',
    color: "blue",
    typeLink: 'dropdown',
    status: 'private',
    link: '#',
    children: [{
      label: 'Réglage du compte',
      typeLink: 'route',
      link: '/utilisateur/mon-compte',
    },
    {
      label: 'Déconnexion',
      typeLink: 'customFn',
      customFn: () => {
        if (!!localStorage && !!localStorage.getItem('userContext')) {
          localStorage.removeItem('userContext');
        }
        document.location.reload();
      }
    }
    ]
  },
]

const menuArrayPrivate: MenuItems[] = [
  {
    label: 'Portrait des QPV',
    icon: "",
    color: "blue",
    typeLink: 'route',
    status: 'public',
    link: '/portrait-national',
  },
  {
    label: 'À propos',
    icon: 'fas fa-caret-down',
    color: "blue",
    typeLink: 'dropdown',
    status: 'public',
    link: '#',
    children: [{
      label: 'Le projet',
      typeLink: 'route',
      link: '/projet',
    },
    {
      label: 'L\'équipe',
      typeLink: 'route',
      link: '/equipe'
    },
    {
      label: 'Méthodologie',
      typeLink: 'route',
      link: '/methodologie',
    },
    {
      label: 'Charte d\'utilisation',
      typeLink: 'route',
      link: '/charte-d-utilisation'
    }
    ]
  },
  {
    label: 'Avec nous',
    typeLink: 'route',
    link: '/avec-nous',
    status: 'public',
    color: "blue",
  },
  {
    label: 'L\'annuaire',
    icon: '',
    color: "blue",
    typeLink: 'route',
    link: '/annuaire',
    status: 'public'
  },
  {
    label: 'Mon compte',
    icon: 'fas fa-user',
    color: "blue",
    typeLink: 'dropdown',
    status: 'private',
    link: '#',
    children: [{
      label: 'Réglage du compte',
      typeLink: 'route',
      link: '/utilisateur/mon-compte',
    },
    {
      label: 'Déconnexion',
      icon: "fas fa-sign-out-alt",
      iconPosition: 'left',
      typeLink: 'customFn',
      customFn: () => {
        if (!!localStorage && !!localStorage.getItem('userContext')) {
          localStorage.removeItem('userContext');
        }
        document.location.reload();
      }
    }
    ]
  },
]

const NavTouch: React.FunctionComponent<{ 
  siteTitle?:string, 
  current?:string, 
  subcurrent?:string 
}> = ({ 
  siteTitle,
  current,
  subcurrent
}) => {

  const { isMenuOpen, stateChangeHandler } = useContext(NavigationContext);


  return (

  <Menu id="navTouch"
    right
    width={'280px'}
    isOpen={isMenuOpen}
    onStateChange={(state: any) => stateChangeHandler(state)}
    customBurgerIcon={false}
  >
    <ul className="menu menu-touch">
      <li className="item-menu link-home">
        <AniLink
          fade
          to="/"
          entry={{ delay: 0.5 }}
          className="btn btn__lbo btn__no-text">
          <svg className="svg svg-logo logo-brand">
            <use xlinkHref="#logoVDQ"></use>
          </svg>
          <span className="btn__label">Back to top</span>
        </AniLink>
        <ButtonBurger />
      </li>
    </ul>
    <OwnMenu name="HeaderMenuMain" menuItems={!!isLoggedIn() ? menuArrayPrivate : menuArray}  side="right" current={current} subcurrent={subcurrent} />
  </Menu>

);
}


export default NavTouch;