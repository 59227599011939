import React, { FunctionComponent, useState } from 'react';
import { mergeWith, isNil } from 'lodash';
import UserContext from './UserContext';

const UserProvider:FunctionComponent<{ children?: any}> = ({ children }) => {

  const setUserContext = ({
    details,
    auth
  }) => {
    updateUser((prevState:any) => {
  
      const newState = { ...prevState };
      
      const result = mergeWith(newState, {
        details,
        auth
      }, (obj, src) => {
        if (!isNil(src) || null === src) {
          return src;
        }
        return obj;
      });

      window.localStorage.setItem("userContext", JSON.stringify(result));

      return result;
    })
  }

  const defaultUser = {
    details: {},
    auth: {},
    setUserContext
  }

  let u = null;

  if(typeof window !== "undefined" && localStorage.getItem("userContext")){
    let uc:any = localStorage.getItem("userContext");
    let datas = JSON.parse(uc);
    u = {
      setUserContext,
      ...datas
     }
  }
  const userState = u ? u : defaultUser;

  const [user, updateUser] = useState(userState);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider