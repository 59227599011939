import React from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import "../../../../assets/scss/_font-awesome/fontawesome.scss";
import "./styles.scss"
import { isLoggedIn } from '../../../services/AuthService';

import { slugify } from "../../../utils/StringUtils";


interface MenuProps {
  name: string;
  menuItems: MenuItems[];
  side?: "right" | "left";
  current?: string;
  subcurrent?: string;
  parent?: string;
}

export interface MenuItems {
  label?: string;
  icon?: string;
  color?: "blue" | "white" | "yellow";
  iconPosition?: "right" | "left";
  img?: string;
  svg?: string;
  width?: string;
  height?: string;
  link?: string;
  typeLink?: string;
  target?: string;
  alt?: string;
  children?: MenuItemsChildren[];
  status?: string;
  active?: boolean;
  onHomepage?: boolean;
}
export interface MenuItemsChildren {
  label?: string;
  icon?: string;
  iconPosition?: "right" | "left";
  link?: string;
  typeLink?: string;
  customFn?: any;
  active?: boolean;
}

export default function OwnMenu({ name, menuItems, side, current, subcurrent, parent }: MenuProps) {

  return (
    <nav className={`menu menu-${side}`}>
      <ul className="menu_inner">
        {menuItems.map((item, i) => {

          if (!!item && !!item.label) {
            item.active = (slugify(item.label) === current);
          }

          return (('public' === item.status || ('private' === item.status && isLoggedIn())) && !(false === item.onHomepage && "home" === current)) ? (
            <li className={`link_wrapp lvl0 item-${(!!item && !!item.label) ? slugify(item.label) : ''}`} key={name + `_menuItem_` + i} data-color={item.color}>
              {(item.typeLink === 'dropdown') ? (
                <>
                  <button className={`link icon-${item.iconPosition} ${!!item.active ? "current" : ""}`} type="button">
                    <span className="link_label">{item.label}</span>
                    {item.icon &&
                      <span className="link_icon"><i className={item.icon}></i></span>
                    }
                  </button>
                  <div className="drop" data-uk-dropdown="mode: click; boundary: window; pos: top-left; delay-hide: 50;">
                    <ul className="lvl1">
                      {item.children && item.children.map((subitem, k) => {

                        if (!!subitem && !!subitem.label) {
                          subitem.active = (slugify(subitem.label) === subcurrent);
                        }

                        return (
                          <li className={`link_wrapp lvl1`} key={name + `_item_` + i + `_menuSubitem_` + k}>
                            {'route' === subitem.typeLink ? (
                              <AniLink className={`sublink icon-${subitem.iconPosition} ${!!subitem.active ? "current" : ""}`}
                                fade
                                entry={{ delay: 0.5 }}
                                to={subitem.link}
                              >
                                <span className={`link_label icon-${subitem.iconPosition}`}>{subitem.label}</span>
                                {subitem.icon &&
                                  <span className="link_icon"><i className={subitem.icon}></i></span>
                                }
                              </AniLink>
                            ) : 'customFn' === subitem.typeLink ?
                              <button type="button" className={`link icon-${subitem.iconPosition} ${!!subitem.active ? "current" : ""}`} onClick={subitem.customFn}>
                                <span className="link_label">{subitem.label}</span>
                                {subitem.icon &&
                                  <span className="link_icon"><i className={subitem.icon}></i></span>
                                }
                              </button>
                              : 'mailto' === subitem.typeLink ? (


                                <a className={`sublink icon-${subitem.iconPosition} ${!!subitem.active ? "current" : ""}`} href={`mailto:${subitem.link}`} target="_blank">
                                  <span className="link_label">{subitem.label}</span>
                                  {subitem.icon &&
                                    <span className="link_icon"><i className={subitem.icon}></i></span>
                                  }
                                </a>
                              ) : (
                                <a className={`sublink icon-${subitem.iconPosition} ${!!subitem.active ? "current" : ""}`} href={subitem.link}>
                                  <span className="link_label">{subitem.label}</span>
                                  {subitem.icon &&
                                    <span className="link_icon"><i className={subitem.icon}></i></span>
                                  }
                                </a>
                              )
                            }
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  {'route' === item.typeLink ? (
                    <AniLink className={`link icon-${item.iconPosition} ${!!item.active ? "current" : ""}`}
                      fade
                      entry={{ delay: 0.5 }}
                      to={item.link}
                    >
                      {(!!item.img || !!item.svg) ? (
                        <>
                          {!!item.svg ?
                            <svg className="svg svg-logo logo-wdd">
                              <use xlinkHref={`#${item.svg}`}></use>
                            </svg>
                            :
                            <img src={item.img} alt=" " />
                          }
                        </>
                      ) : (
                        <>
                          <span className="link_label" dangerouslySetInnerHTML={{ __html: item.label }}></span>
                          {item.icon &&
                            <span className="link_icon"><i className={item.icon}></i></span>
                          }
                        </>
                      )
                      }
                    </AniLink>
                  ) : (
                    <a className={`link icon-${item.iconPosition} ${!!item.active ? "current" : ""}`} href={item.link} target='_blank' rel='noopener'>
                      {(!!item.img || !!item.svg) ? (
                        <>
                          {!!item.svg ?
                            <svg className="svg svg-logo logo-wdd">
                              <use xlinkHref={`#${item.svg}`}></use>
                            </svg>
                            :
                            <img src={item.img} alt=" " />
                          }
                        </>
                      ) : (
                        <>
                          <span className="link_label">{item.label}</span>
                          {item.icon &&
                            <span className="link_icon"><i className={item.icon}></i></span>
                          }
                        </>
                      )
                      }
                    </a>
                  )}
                </>
              )
              }
            </li>
          ) : null
        })}
        {(!!parent && parent === 'footer') &&
            <li className={`link_wrapp lvl0 item-cookie-management`} data-color='white' dangerouslySetInnerHTML={{
            __html: `
                        <a class='link' href='javascript: Cookiebot.renew()'>
                        <span class="link_label">Gestion des cookies</span>
                            </a>`}}>
          </li>
        }
      </ul>
    </nav>
  )
}